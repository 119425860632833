<template lang="pug">
.SelectZone
  section.App.text-center
    figure.containerImgZone
      img(src="@/assets/mobileFSFB/helpingBoy.png" alt="helpingBoy").h-100.containerImgZone-img.w-100
      .containerImgZone-tape
    .w-100.d-flex.justify-content-center
      img(src="@/assets/mobileFSFB/logoFSFB.png" alt="logoFSFB").logoAuthZone.mx-auto
    .text-start.boxText.boxText2.mx-auto
      .boxContent.pb-3
        h2.titleFSFBM Bienvenido
        p.desc2
          | Elige la sede en la que te encuentras
        .pt-4.d-block
          .boxContent1.px-3.pt-4
            .form-select-container.mb-3.position-relative.mt-2
              b-form-select.Input-text.Input-textSede.p-2.pr-5(
                v-model="selectedSede"
                :options="sedes"
                size="sm"
                autocomplete="off"
                placeholder="Sede"
                required
                :style="selectedSede ? 'background: var(--color-white);' : ''"
              ).position-relative
              p.textSede Sede
              svg.mr-2(width='21' height='21' viewbox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg').z-negative-2
                path(d='M10.6375 4.23521C11.9577 4.23521 13.2482 4.62851 14.3459 5.36536C15.4436 6.10222 16.2992 7.14952 16.8044 8.37486C17.3096 9.6002 17.4418 10.9485 17.1842 12.2493C16.9267 13.5501 16.291 14.745 15.3574 15.6829C14.4239 16.6207 13.2346 17.2594 11.9397 17.5181C10.6449 17.7769 9.3028 17.6441 8.0831 17.1365C6.86341 16.629 5.82092 15.7694 5.08746 14.6667C4.354 13.5639 3.96252 12.2674 3.96252 10.9411C3.96252 9.16259 4.66578 7.45692 5.91758 6.19932C7.16938 4.94173 8.86719 4.23521 10.6375 4.23521Z' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                path(d='M10.6376 12.2815L12.4209 10.49' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                path(d='M8.85413 10.49L10.6374 12.2815' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            button(
              :disabled="!selectedSede || selectedSede === 'Sede'"
              @click="continueToServices(selectedSede)"
            ).buttonForm.d-block.w-100.mt-4
              span(v-if="!charging") Continuar
              span(v-else)
                MiniSpinner
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SelectZone",

  data() {
    return {
      selectedSede: ""
    };
  },

  components: {
    MiniSpinner: () => import("./MiniSpinner.vue")
  },

  computed: {
    ...mapState({
      options: state => state.mobileFSFB.tipide,
      error: state => state.mobileFSFB.error,
      charging: state => state.mobileFSFB.charging,
      sites: state => state.mobileFSFB.sites
    }),
    sedes() {
      let sites = [
        ...this.sites.map(s => {
          return { text: s.name, value: s.code };
        })
      ];
      return sites;
    }
  },

  methods: {
    continueToServices(sede) {
      // this.$router.push({ path: ("/mobile_fsfb/" + sede), query: { embebedData: this.$route.query.embebedData } });
      this.$router.replace({
        params: { code: sede },
        query: { embebedData: this.$route.query.embebedData }
      });
      this.$emit("changeMode", "Services");
    }
  }
};
</script>

<style lang="scss">
.SelectZone {
  max-width: 450px;
  width: 100%;
  position: relative;
  height: 100%;
}

.containerImgZone {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.containerImgZone-img {
  object-fit: cover;
  object-position: top;
}

.containerImgZone-tape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    359.49deg,
    var(--color-bg-dark) 2.94%,
    rgba(32, 40, 87, 0.661458) 56.73%,
    rgba(33, 30, 23, 0) 99.46%
  );
}

.logoAuthZone {
  top: 0;
  margin-top: 56px;
  margin-bottom: 50%;
  position: relative;
}

.boxText {
  position: absolute;
  bottom: 0;
}

.boxText2 {
  width: 100%;
  padding: 0 21px;
  padding-bottom: 119px;
  overflow: auto;
  position: relative;
}

.desc2 {
  font-size: 18px;
  line-height: 25px;
  color: var(--color-white);
}

.boxContent1 {
  width: 100%;
  height: 192px;
  background: var(--color-white);
  box-shadow: 0px 0px 1px rgba(21, 21, 21, 0.02),
    0px 0px 4px rgba(21, 21, 21, 0.08);
  border-radius: 22px;
}

.buttonForm {
  height: 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  border-radius: 16px;
  transition: 0.5s all;
  background: var(--color-radiant);
  color: var(--color-white);
}

.buttonForm:disabled {
  color: #dedede;
  background: none;
}

.Input-textSede {
  background: none;
}

.textSede {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.form-select-container svg {
  background: var(--color-white);
  right: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
